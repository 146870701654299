export const isDarkMode = (hexColor) => {
  let hex = hexColor.replace(/^#/, "");
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Normalize the RGB values to the range [0, 1]
  r /= 255;
  g /= 255;
  b /= 255;

  // Apply the sRGB gamma correction
  r = r <= 0.03928 ? r / 12.92 : Math.pow((r + 0.055) / 1.055, 2.4);
  g = g <= 0.03928 ? g / 12.92 : Math.pow((g + 0.055) / 1.055, 2.4);
  b = b <= 0.03928 ? b / 12.92 : Math.pow((b + 0.055) / 1.055, 2.4);

  // Calculate the relative luminance
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // Determine if the color is suited for dark mode
  // returns true if the provided hex color is used for dark mode
  return luminance < 0.5;
};
