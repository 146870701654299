const german = {
  heading: "Sende diese E-Mail an NTS",
  description:
    "Haben Sie Bedenken, dass dies eine bösartige E-Mail sein könnte? Senden Sie sie zur Analyse an das NTS Defense Team.",
  txtComment: "Kommentar (optional)",
  btnSend: "Senden",
  alreadyClicked: "Haben sie bereits einen verdächtigen Link angeklickt oder einen Anhang geöffnet?",
  yes: "Ja",
  no: "Nein",
  statusSuccess:
    "Erfolgreich gesendet! Vielen Dank, dass Sie diese Email gemeldet haben! Wir melden uns bei Ihnen, sobald wir die Email auf verdächtige Aktivität analysiert haben.",
  statusError: "Ein Problem ist aufgetreten, wenden sie sich an uns oder versuchen sie es später noch einmal.",
  sendingMsg: "Senden ...",
  error: "Diese Erweiterung ist nicht für Sie, oder Ihre Umgebung gedacht.",
  progress: "Laden ...",
};
const english = {
  heading: "Send your E-Mail to NTS",
  description: "Suspect that this is a malicious email? Send it to the NTS Defense Team for analysis.",
  txtComment: "Comment (optional)",
  btnSend: "Send",
  alreadyClicked: "Have you already clicked on a suspicious link or opened an attachment?",
  yes: "Yes",
  no: "No",
  statusSuccess:
    "Successfully sent! Thank you for reporting this email. We will get back to you once we have analyzed it for malicious activity.",
  statusError: "An error occurred, please contact us or try it again later.",
  sendingMsg: "Sending ...",
  error: "This Add-in is not meant for you, or your environment.",
  progress: "Loading ...",
};
//declare more languages

//choose Language automatically
export const checkLanguage = (localLanguage) => {
  if (localLanguage.includes("de")) {
    return german;
  } else {
    return english;
  }
};
