import * as React from "react";
import PropTypes from "prop-types";
import { Spinner } from "@fluentui/react-components";

const Progress = (props) => {
  const { message } = props;
  return (
    <>
      <section className="ms-welcome__progressvis ms-u-fadeIn500">
        <Spinner size="large" label={message} />
      </section>
    </>
  );
};

Progress.propTypes = {
  message: PropTypes.string,
};

export default Progress;
