import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles, Text } from "@fluentui/react-components";
import { ErrorCircleFilled } from "@fluentui/react-icons";

const useStyles = makeStyles({
  div1: {
    textAlign: "center",
    margin: "10px",
  },
  icon: {
    fontSize: "48px",
    margin: "10px",
  },
});

const Error = (props) => {
  const { message } = props;
  const styles = useStyles();

  return (
    <div className={styles.div1}>
      <ErrorCircleFilled primaryFill="red" className={styles.icon} />
      <br />
      <Text>{message}</Text>
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string,
};

export default Error;
