import * as React from "react";
import App from "./components/App";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { checkLanguage } from "./classes/languages";
import { isDarkMode } from "./classes/isDarkMode";
import Progress from "./components/Progress";
import { FluentProvider, webLightTheme, webDarkTheme } from "@fluentui/react-components";
import ReactDOM from "react-dom";
import { useState, useEffect, useCallback } from "react";

/* global document, Office, module, console */

// Define Root component
const RootComponent = () => {
  const [language, setLanguage] = useState(null);
  const [fluentTheme, setFluentTheme] = useState(webLightTheme);

  // useCallback for memoizing functions
  const initializeOffice = useCallback(() => {
    Office.onReady(() => {
      const officeTheme = Office.context.officeTheme;
      const isDark = isDarkMode(officeTheme.bodyBackgroundColor);
      console.log("isDarkMode: " + isDark);

      if (isDark) {
        setFluentTheme(webDarkTheme);
      }

      const localLanguage = Office.context.displayLanguage;
      console.log("localLanguage: " + localLanguage);
      const language = checkLanguage(localLanguage);
      setLanguage(language);
    });
  }, []);

  // Initialize Office on component mount
  useEffect(() => {
    initializeOffice();
  }, [initializeOffice]);

  if (!language) {
    console.log("render Progress");
    return (
      <div style={{ minHeight: "100vh", margin: "10px" }}>
        <Header />
        <Progress message={"Loading ..."} />
        <Footer />
      </div>
    );
  }

  console.log("render App");
  return (
    <FluentProvider theme={fluentTheme}>
      <App language={language} />
    </FluentProvider>
  );
};

// Wrap RootComponent with React.memo and assign a display name
const Root = React.memo(RootComponent);
Root.displayName = "Root";

// Separate function to handle Office initialization
const initializeRoot = () => {
  const rootElement = document.getElementById("container"); // root
  if (rootElement) {
    console.log("render root");
    ReactDOM.render(<Root />, rootElement);
  }

  if (module.hot) {
    module.hot.accept("./components/App", () => {
      console.log("module.hot.accept()");
    });
  }
};

// Initialize the app when Office is ready
Office.onReady(initializeRoot);
