import * as React from "react";
import { callApi } from "../classes/callApi";
import Error from "./Error";
import { getUserAccessToken } from "../classes/getUserAccessToken";
import Menu from "./Menu";
import Footer from "./Footer";
import Progress from "./Progress";
import Header from "./Header";
import PropTypes from "prop-types";
import { useState, useEffect, useRef } from "react";

/* global console */

const App = (props) => {
  const { language } = props;
  const progress = language.progress;
  const error = language.error;

  const [loading, setLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [token, setToken] = useState(""); // Use state for the token
  const isFirstRun = useRef(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        const fetchedToken = await getUserAccessToken();
        if (fetchedToken !== "") {
          setToken(fetchedToken);
          const authorized = await callApi("/api/authorize", fetchedToken, false);
          setIsAuthorized(authorized);
        } else {
          console.log("Failed to retrieve the token!");
          setIsAuthorized(false);
        }
      } catch (err) {
        console.log("Failed to authorize! error: " + err);
        setIsAuthorized(false);
      }
      setLoading(false);
    };
    if (isFirstRun.current) {
      isFirstRun.current = false;
      initialize();
    }
  }, []);

  console.log(loading ? "render Progress" : isAuthorized === false ? "render Error" : "render Menu");
  return (
    <div className="taskpane-content">
      <Header />
      <main style={{ flex: "1 1 auto", overflowY: "auto", padding: "15px" }}>
        {loading ? (
          <Progress message={progress} />
        ) : isAuthorized === false ? (
          <Error message={error} />
        ) : (
          <Menu language={language} token={token} />
        )}
      </main>
      <Footer />
    </div>
  );
};

App.propTypes = {
  language: PropTypes.object,
};

export default App;
