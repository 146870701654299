/* global console */

import axios from "axios";

export const callApi = async (api, token, payload) => {
  let response;
  console.log("API: " + api);
  if (payload === false) {
    // authorize
    response = await axios
      .get(api, {
        headers: {
          "Content-Type": "application/json",
          "X-API-Authorization": `Bearer ${token}`,
        },
      })
      .catch((exception) => {
        console.error("Call API: exception", exception.response || exception);
        return false;
      });
  } else {
    // post EML file
    response = await axios
      .post(api, {
        headers: {
          "Content-Type": "application/json",
          "X-API-Authorization": `Bearer ${token}`,
        },
        data: payload,
      })
      .catch((exception) => {
        console.error("Call API: exception", exception.response || exception);
        return false;
      });
  }
  console.log("Call API: Status: " + response.status + ", Data: " + response.data);
  return response.status === 200;
};
