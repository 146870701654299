import * as React from "react";
import { makeStyles, Image } from "@fluentui/react-components";

const useStyles = makeStyles({
  logoContainer: {
    position: "sticky",
    paddingTop: "10px",
    top: 0,
    zIndex: 10,
  },
  logo: {
    margin: "0 auto",
    maxWidth: "250px",
    display: "block",
  },
});

const Header = () => {
  const styles = useStyles();

  return (
    <header className={styles.logoContainer}>
      <Image src="../../assets/nts-rlwc.png" alt="NTS Relax We Care Logo" className={styles.logo} />
    </header>
  );
};

export default Header;
