import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Button, Text, Textarea, RadioGroup, Radio, Label, Title3 } from "@fluentui/react-components";
import { CheckmarkCircleFilled, DismissCircleFilled } from "@fluentui/react-icons";
import Progress from "./Progress";
import { callApi } from "../classes/callApi";

/* global Office clearTimeout setTimeout*/

const useStyles = makeStyles({
  div1: {
    textAlign: "center",
  },
  divTextarea: {
    position: "relative",
    width: "100%",
    height: "150px",
    margin: "0 auto",
    marginBottom: "10px",
  },
  textarea: {
    width: "100%",
    paddingBottom: "5px",
    height: "150px",
    boxSizing: "border-box",
  },
  divCounter: {
    position: "absolute",
    bottom: "7px",
    right: "18px",
    fontSize: "12px",
    color: "#888",
  },
  div2: {
    textAlign: "center",
    margin: "10px",
  },
  icon: {
    fontSize: "48px",
  },
  divMargin: {
    marginBottom: "10px",
  },
});

const Menu = (props) => {
  const { language, token } = props;
  const styles = useStyles();

  const [clicked, setClicked] = useState(false);
  const [comment, setComment] = useState("");
  const [counter, setCounter] = useState(0);
  const [uploadStatus, setUploadStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async () => {
    setUploadStatus(null);
    setIsLoading(true);
    const timer = startTimeoutTimer(45000); // unit: ms
    const itemId = Office.context.mailbox.convertToRestId(
      Office.context.mailbox.item.itemId,
      Office.MailboxEnums.RestVersion.v2_0
    );
    const payload = JSON.stringify({
      comment: comment,
      clicked: clicked,
      itemId: itemId,
    });
    const uploaded = await callApi("/api/postEmlFile", token, payload);
    setUploadStatus(uploaded);
    clearTimeout(timer);
    setIsLoading(false);
  };

  const startTimeoutTimer = (ms) => {
    return setTimeout(() => {
      setUploadStatus(false);
      setIsLoading(false);
    }, ms);
  };

  return (
    <div className={styles.div1}>
      {(uploadStatus === false || uploadStatus === null) && (
        <>
          <div className={styles.divMargin}>
            <Title3>{language.heading}</Title3>
          </div>
          <div className={styles.divMargin}>
            <Text>{language.description}</Text>
          </div>
          <div className={styles.divTextarea}>
            <Textarea
              placeholder={language.txtComment}
              value={comment}
              resize="none"
              onChange={(event) => {
                if (event.target.value.length <= 500) {
                  setComment(event.target.value);
                  setCounter(event.target.value.length);
                }
              }}
              className={styles.textarea}
            />
            <div className={styles.divCounter}>{counter}/500</div>
          </div>
          <div className={styles.divMargin}>
            <Label>{language.alreadyClicked}</Label>
            <RadioGroup
              value={clicked ? "true" : "false"}
              layout="horizontal"
              required
              onChange={(event) => {
                setClicked(event.target.value === "true");
              }}
              style={{ display: "block" }}
            >
              <Radio value="true" label={language.yes} />
              <Radio value="false" label={language.no} />
            </RadioGroup>
          </div>
          <Button appearance="primary" onClick={sendEmail} disabled={isLoading}>
            {language.btnSend}
          </Button>
        </>
      )}
      {isLoading && (
        <div style={{ margin: "10px" }}>
          <Progress message={language.sendingMsg} />
        </div>
      )}
      {uploadStatus !== null && (
        <div className={styles.div2}>
          {uploadStatus === true ? (
            <>
              <CheckmarkCircleFilled primaryFill="green" className={styles.icon} />
              <br />
              <Text>{language.statusSuccess}</Text>
            </>
          ) : uploadStatus === false ? (
            <>
              <DismissCircleFilled primaryFill="red" className={styles.icon} />
              <br />
              <Text>{language.statusError}</Text>
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

Menu.propTypes = {
  language: PropTypes.object,
  token: PropTypes.string,
};

export default Menu;
