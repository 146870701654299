import * as React from "react";
import { makeStyles, Image } from "@fluentui/react-components";

const useStyles = makeStyles({
  footer: {
    position: "sticky",
    bottom: 0,
    zIndex: 10,
    padding: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
  },
  cube: {
    maxHeight: "42px",
    display: "block",
  },
  text: {
    maxHeight: "40px",
    display: "block",
  },
});

const Footer = () => {
  const styles = useStyles();
  return (
    <footer className={styles.footer}>
      <Image src="../../assets/nts-defense-cube.svg" alt="Defense Cube Logo" className={styles.cube} />
      <Image src="../../assets/nts-defense-text.svg" alt="Defense Text" className={styles.text} />
    </footer>
  );
};

export default Footer;
